.loader {
  
}

.loader circle {
  fill: none;
  stroke-width: 15;
  animation-name: preloader;
  animation-duration: 2200ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transform-origin: 170px 170px;
  will-change: transform;
}

.loader circle:nth-of-type(1) {
  stroke-dasharray: 350px 400px;
}
.loader circle:nth-of-type(2) {
  stroke-dasharray: 300px 350px;
}
.loader circle:nth-of-type(3) {
  stroke-dasharray: 250px 300px;
}
.loader circle:nth-of-type(4) {
  stroke-dasharray: 200px 250px;
}

.loader circle:nth-of-type(1) {
  animation-delay: -0.10s;
}
.loader circle:nth-of-type(2) {
  animation-delay: -0.20s;
}
.loader circle:nth-of-type(3) {
  animation-delay: -0.30s;
}
.loader circle:nth-of-type(4) {
  animation-delay: -0.40s;
}


@keyframes preloader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg);
  }
}
