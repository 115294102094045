.table :global(.new){
  animation: new 1500ms;
}

.table :global(.updated){
  animation: updated 1500ms;

}

@keyframes new{
  0% {
    background-color: rgba(14, 159,216, 0);
  }

  10% {
    background-color: rgba(14, 159,216, 0.5);
  }

  100% {
    background-color: rgba(14, 159,216, 0);
  }
}

@keyframes updated{
  0% {
    background-color: rgba(229, 224, 19, 0);
  }

  10% {
    background-color: rgba(229, 224, 19, 0.5);
  }

  100% {
    background-color: rgba(229, 224, 19, 0);
  }
}