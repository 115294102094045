.container {
  min-width: 100%;
  position: absolute;
  margin: auto 0;
  top: 0;
  bottom: 0;
  align-items: center;
  background-color: #182e4d;
}

.container > * {
  overflow: hidden;
  padding: 10px;
}

.logo {
  float: right;
}
.reactIcon {
  font-size: 1.3em;
  margin-bottom: -0.25em;
}

.headerIcon {
  margin-bottom: -5px;
  margin: auto 10px -5px 0;
  font-size: 25px;
}

.container a {
  color: #0568D1;
}

.container a:hover {
  color: #0463c7;
}

/*@media (min-width: 800px) {
  .logo {
    margin-right: 70px;
  }

}*/

@media (min-width: 1150px) {

  .container > * {
    padding: 70px;
  }
}

@media (max-width: 1150px) {
  .logo {
    float: none;
  }
  .container > * {
    text-align: center;
  }
  .container > * > * {
    text-align: left;
  }
}

@keyframes appearFromLeft{
  0% {
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes appear{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shake{
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }
}


.logo {
  animation-duration: 1300ms;
  animation-name: appear;
}
.hasError {
  animation-duration: 150ms;
  animation-name: shake;
}

@media (min-width: 1150px) {
  .loginAppear {
    animation-duration: 800ms;
    animation-name: appearFromLeft;
  }
}
